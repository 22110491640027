import http from "./http"
const testUrl = 'https://api.pay.gxb.cn/api'
// # 买/卖状态0未付款, 1 已付款, 2 已发货, 3 已签收, 4 退货/退款申请, 5 退货中, 6 已退货/退款, 7 取消交易, 8 结货款, 9 交易完成
/** 订单列表   @param { token,   marker:{1买家、2卖家}, type1:{全部、2待付款、3代发货、4已完成、5售后} } */
export const $apiOrderList = (data) => http({ method: 'get', url:'/user/order', params: data }) 
/** 订单详情   @param { token, id:orderId } */
export const $apiOrderDetails = (data) => http({ method: 'get', url:'/user/order/info', params: data })
/** 编辑详情   @param { token, id:orderId,type:1取消订单 2删除订单 3已签收,4退货/退款申请,5退货中 } */
export const $apiEditOrder = (data) => http({ method: 'post', url:'/user/order/state', params: data })
// 交易流水 @param{ token }
export const $apiDealFlow = (data) => http({ method: 'post', url:'/user/capital/water', params: data })
// 交易流水详情 @param{ token，jno_acct:账务系统资金流水唯一标识，userType:用户类型 INNERUSER：个人用户;INNERCOMPANY：企业用户 }
export const $apiDealFlowDetail = (data) => http({ method: 'post', url:'/user/capital/waterInfo', params: data })
// 电子提现回单信息
export const $apiWithdrawalReceipt = (data) => http({ method: 'post', url:testUrl+'/withdrawal/form/information', params: data })

// api:合同管理
/** @name "合同列表" => @param { status ,data:{token, type: 1全部, 2买方, 3卖方}}*/
export const $apiContractList = (data) => http({ method: "post", url: "/Goods/Web/ContractList", params: data })
/** @name "合同列表" => @param { status ,data:{token, type: 1全部, 2买方, 3卖方}}*/
export const $apiSettlementContractList = (data) => http({ method: "post", url: "/Goods/Web/SettleContractList", params: data })
/** @name "合同详情" => @param {contract}  */
export const $apiContractDetails = (data) => http({ method: "post", url: "/Goods/Web/getContract", params: data })
/** @name "修改和确认合同" => @param {contract}  */
export const $apiConfirmContract= (data) => http({ method: "post", url: "/Goods/Web/upContract", params: data })
/** @name "单独修改合同单价" => @param {contract}  */
export const $apiSetContractPrice= (data) => http({ method: "post", url: "/Goods/Web/priceTrim", params: data })
/** @name "合同提示" => @param {token}  */
export const $apiContractPrompt = ( data ) =>http({ method: "get", url: "/Goods/Web/ContractTips", params: data })
/** @name "保证金" => @param {token}  */
export const $apiSecurityList = ( data ) =>http({ method: "get", url: "/Goods/Web/suretyRecord", params: data })
