import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ba8d044"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "paginationBox" }

import {ref, watch} from 'vue'

export default {
  props: {
  total:Number,
  changePage:Number,
  pageSize:{
    type:Number,
    default:10,
  }
},
  emits: ['getCurrentPage'],
  setup(__props, { emit: emits }) {

const props = __props

let current_page = ref(1)


watch(()=>props.changePage,(val)=>{
  current_page.value = props.changePage
})

const changePageHandler = (params) => {
  // 页数不允许小数
  if(params.toString().includes('.')){
    params = Math.floor(params)
    current_page.value = params
  }
  emits('getCurrentPage',params)
}

return (_ctx, _cache) => {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      layout: "prev, pager, next,jumper",
      total: __props.total,
      "page-size": __props.pageSize,
      "current-page": _unref(current_page),
      "onUpdate:current-page": _cache[0] || (_cache[0] = $event => (_isRef(current_page) ? (current_page).value = $event : current_page = $event)),
      onCurrentChange: changePageHandler,
      precision: 1
    }, null, 8, ["total", "page-size", "current-page"])
  ]))
}
}

}